<template lang="html">
  <div class="component-wrapper">
      <div v-if="!passwordReset" class="forgot-password-div">
          <h1 class="mb-3">Reset your password</h1>
          <p>Almost there. Just enter a new password and you are good to go!</p>
          <b-form method="post">
              <b-input v-model="newPassword" class="mb-2 forgot-password-input"
                       placeholder="Password"
                       type="password">
              </b-input>
              <button id="forgot-email-button" @click.prevent="resetPassword">
                  <b>Confirm</b>
              </button>
          </b-form>
      </div>
      <div v-else class="forgot-password-div">
          <p>Password successfully reset! Please login to continue.</p>
          <button id="reset-password-login-button" @click="$parent.showLogin =! $parent.$parent.showLogin">
              Login
          </button>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            newPassword: '',
            passwordToken: window.location.pathname.split('/').pop(),
            passwordReset: false
        }
    },
    methods: {
        resetPassword() {
            this.emailSent = true
            this.$http.post(`${this.$store.state.endpoints.baseAuthUrl}password_reset/confirm/`,
                {
                    'token': this.passwordToken,
                    'password': this.newPassword
                }
            ).then(() => {
                this.passwordReset = true
            })
        }
    }
}
</script>

<style lang="css" scoped>
.forgot-password-div {
    padding: 2em;
    border-radius: 1em;
    width: 66%;
    box-shadow: 3px 3px 3px 5px var(--darkBackground);
    color: white;
    height: 50vh;
}
.forgot-password-input {
    width: 66%;
}
@media (max-width: 792px) {
    .forgot-password-input {
        width: 100%;
    }
    .forgot-password-div {
        width: 100%;
    }
}
#forgot-email-button {
    background: white;
    box-shadow: 3px 3px 3px var(--darkBackground),
                        -1px -1px 3px var(--background);
    border-radius: 1em;
    width: 8em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--darkBackground);
    align-self: center;
    padding: 0.5em;
    border: none;
}
#reset-password-login-button {
    padding: 1em;
    margin-bottom: 1em;
    margin-top: 1em;
    border-radius: 1em;
    box-shadow: var(--globalButtonShadow);
    border: none;
    background: white;
    color: white;
    color: var(--darkBackground);
    font-weight: bold;
    min-width: 5em;
}

#forgot-email-button:active {
    transform: translateY(4px);
    -webkit-transition-duration: 0.2s; /* Safari */
    transition-duration: 0.2s;
}

#forgot-email-button:hover {
    color: white;
    background: var(--hoverColor);
}

#reset-password-login-button:active {
    transform: translateY(4px);
    -webkit-transition-duration: 0.2s; /* Safari */
    transition-duration: 0.2s;
}

#reset-password-login-button:hover {
    color: white;
    background: var(--hoverColor);
}

</style>
